.AboutMe_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 70px;
  .about_me {
    background-color: white;
    color: var(--primary-text-color);
    box-shadow: 0 2px 4px #2d2f3b1f;
    padding: 40px;
    margin: 10px 0px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
  }
  .skills_container {
    .skills {
      background-color: white;
      color: var(--primary-text-color);
      box-shadow: 0 2px 4px #2d2f3b1f;
      padding: 20px 20px;
      margin: 45px 0px;
      padding-bottom: 45px;
    }
  }
}
