@import "src/breakpoints.scss";

.Experience_container {
  padding-top: 70px;
  .work_experience_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--primary-text-color);
    margin: 50px 0px;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      .company_icons_container {
        display: flex;
        flex-direction: row !important;
        div {
          margin-right: 20px !important;
        }
      }
      .job_details {
        margin: 0px !important;

        .job_title {
          flex-direction: column;
          text-align: center;
          .uncenter_row {
            flex-direction: column;
          }
          h2 {
            font-size: 1.2rem !important;
          }
          .small_text {
            font-size: 0.7rem !important;
          }
        }
      }
    }

    border-radius: 20px;
    padding-bottom: 45px;
  }
}
