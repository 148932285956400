@import "src/breakpoints.scss";

.FeaturedProjectCard_container {
  background-color: white;
  box-shadow: 0 2px 4px #2d2f3b1f;
  margin: 25px 0px;
  display: flex;
  flex-direction: row;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column-reverse;
    max-width: 400px;

    .project_details {
      padding: 20px 20px 40px !important;

      .title_section {
        margin-bottom: 10px;
      }
    }

    img {
      border-top-right-radius: 15px !important;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 0px !important;
      width: 100%;
      max-height: 300px;
    }
  }

  .project_details {
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title_section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .links {
        font-size: 25px;
        svg {
          cursor: pointer;
          color: var(--blue);

          &:last-child {
            margin-right: 5px;
          }
        }
      }
    }

    .description {
      color: var(--primary-text-color);
      margin-bottom: 20px;
    }

    .technologies {
      margin-right: 10px;
    }
  }
  .project_image {
    display: flex;
    justify-content: center;
  }

  .project_image_right {
    img {
      max-width: 400px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  
  .project_image_left {
    img {
      max-width: 400px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

}

.reverse {
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column-reverse;
  }
}