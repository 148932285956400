@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400&display=swap");
html {
  background-color: #f7f7f9 !important;
  scroll-behavior: smooth;
}
body {
  font-family: "Manrope", sans-serif !important;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
