:root {
  --blue: #0076b1;
  --light_blue: #34bbff;
  --grey: #1d1d1d;
  --primary-text-color: #2d2f3b;
  --breakpoint-tablet: 768px;
  --breakpoint-phone: 600px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
}

.content_container {
  max-width: 1000px;
  width: 100%;
}

.primary_dark_grey {
  background-color: #1d1d1d;
}
.secondary_black {
  background-color: #000000;
}
.blue_highlight {
  background-color: var(--blue);
}

.blue_text {
  color: var(--light_blue);
}

.dark_blue_text {
  color: var(--blue);
}

.uncenter_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.large_title_text {
  color: var(--primary-text-color);
  font-size: 4rem !important;
  margin: 0px;
  font-weight: 400;
}

.primary_title_text {
  margin: 0px;
  color: var(--primary-text-color);
  font-size: 2rem !important;
  white-space: nowrap;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .primary_title_text {
    font-size: 2rem !important;
  }
  .large_title_text {
    font-size: 3rem !important;
  }
}

@media screen and (max-width: 400px) {
  .primary_title_text {
    font-size: 1.5rem !important;
  }
  .large_title_text {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 300px) {
  .primary_title_text {
    font-size: 1.25rem !important;
  }
  .large_title_text {
    font-size: 2rem !important;
  }
}

.secondary_text {
  margin: 0px;
  color: var(--primary-text-color);
  font-size: 1.5rem !important;
  font-weight: 400;
}

.medium_text {
  margin: 0px;
  color: var(--primary-text-color);
  font-size: 1.25rem !important;
  font-weight: 400;
}

.small_text {
  margin: 0px;
  color: var(--primary-text-color);
  font-size: 1rem !important;
}

.primary_white_text {
  margin: 0;
  color: var(--primary-text-color);
}

.round_border {
  border-radius: 15px;
}

p {
  margin: 0;
}

.section_heading {
  margin: 25px 0px;
}

.sub_heading {
  color: var(--light_blue);
  font-size: 1.5rem;
  margin: 10px 0px;
}

.button {
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 2.75rem;
  min-width: 8.75rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  font-size: 15px;
}

.button:hover {
  background-color: var(--light_blue);
  cursor: pointer;
}

.line_seperator {
  border-color: var(--light_blue);
  width: 200px;
  margin: 0px 20px;
}

.show_more_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.show_more_btn svg {
  font-size: 50px;
  color: var(--blue);
  cursor: pointer;
}

.no_padding_bottom {
  padding-bottom: 0px;
}

.text_ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
