.Skills_Cont {
  padding-top: 70px;
  .skills {
    background-color: white;
    color: var(--primary-text-color);
    box-shadow: 0 2px 4px #2d2f3b1f;
    padding: 40px;
    margin: 45px 0px;
  }
}
