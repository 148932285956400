@import "src/breakpoints.scss";

.Homepage_container {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  padding-top: 70px;

  @media screen and (max-width: 600px) {
    #main_cont {
      flex-direction: column !important;
      // transform: scale(0.8);
    }
    .image_border {
      width: 300px !important;
      height: 300px !important;
      margin: 20px 0 !important;
      .headshot_image {
        width: 280px !important;
        margin: 50px 20px !important;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .image_border {
      width: 280px !important;
      height: 280px !important;
      .headshot_image {
        width: 260px !important;
      }
    }
  }

  @media screen and (max-width: 300px) {
    .image_border {
      width: 260px !important;
      height: 260px !important;
      .headshot_image {
        width: 240px !important;
      }
    }
  }

  @media (max-width: $breakpoint-phone) {
    #main_cont {
      flex-direction: column !important;
    }
    .headshot_image {
      width: 100% !important;
      margin: 50px 20px !important;
      max-width: 290px;
    }
    .intro_container {
      text-align: center;
    }
  }
  .image_border {
    border-radius: 50%;
    background: linear-gradient(#00acdf, #7ce8ff, #ccf9ff);
    width: 320px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    .headshot_image {
      width: 300px;
      height: auto;
      border-radius: 50%;
      //box-shadow: 12px 0 #00acdf, 24px 0 #7ce8ff, 36px 0 #ccf9ff;
      margin: 10px;
    }
  }

  .contact_icons {
    display: flex;
    border-radius: 20px;
    font-size: 30px;
    color: var(--blue);
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px #2d2f3b1f;
    margin: 5px;
  }
  .contact_icons:hover {
    color: var(--light_blue);
    cursor: pointer;
  }
}
