.company_icon {
  background-color: white;
  box-shadow: 0 2px 4px #2d2f3b1f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-bottom: 15px;
  transition: all 0.15s ease-in-out;
  height: 55px;

  p {
    margin: 0;
    padding: 10px 5px;
  }
  img {
    max-width: 50px;
    padding: 5px;
  }
}

.active_icon {
  cursor: pointer;
  transform: scale(1.15);
  box-shadow: 0 0px 1px 0 var(--blue), 0 3px 10px 0 var(--blue);
}

.company_icon:hover {
  cursor: pointer;
  transform: scale(1.15);
  box-shadow: 0 0px 1px 0 var(--blue), 0 3px 10px 0 var(--blue);
}
