.Projects_container {
  padding-top: 70px;

  .featured_projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
    .show_more_btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 130px;
      svg {
        font-size: 50px;
        color: var(--blue);
        cursor: pointer;
      }
    }
  }
  .projects_container {
    .projects_row {
      display: flex;
      justify-content: center;
    }
  }
}
